import { 
  COLOR_WARM_GREY,
  createSliceLayout,
  SliceBlockThumbnailGrid,
  SliceContentBlockLinks,
  SliceImageBlocks,
} from '@shapeable/ui';

import { ProductGrid } from '../entities/product-grid';
import { SliceProductBenefits } from '../entities/slice-product-benefits';
import { SliceProductIndustries } from '../entities/slice-product-industries';
import { SCOPE_ORANGE } from '../../theme';

export const SliceLayoutBenefits = createSliceLayout(
  SliceProductBenefits, {
    verticalPadding: 'large',
  }
);

export const SliceLayoutProductIndustries = createSliceLayout(
  SliceProductIndustries, {
    verticalPadding: 'small',
    boundary: 'content',
    defaultBackgroundColor: COLOR_WARM_GREY,
  }
);

export const SliceLayoutProductGrid = createSliceLayout(
  ProductGrid, {
    verticalPadding: 'small',
    boundary: 'content',
    props: {
      desktopAutoColumns: 2,
      tabletAutoColumns: 2,
    }
  }
);

export const SliceLayoutContentBlockLinks = createSliceLayout(
  SliceContentBlockLinks, {
    verticalPadding: 'medium',
    props: {
      hoverColor: SCOPE_ORANGE,
    }
  }
);

export const SliceLayoutBlockThumbnailCardGrid3Columns = createSliceLayout(
  SliceBlockThumbnailGrid, {
    verticalPadding: 'none',
    boundary: 'none',
    props: {
      variant: 'card',
      columns: 0,
      spacing: 0.5,
      tabletMinColumns: 2,
      desktopMinColumns: 3,
      desktopLargeAutoColumns: 3,
      desktopFullHdAutoColumns: 3
    }
  }
);
